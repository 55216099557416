<template>
  <section>
    <div class="container">
      <!-- <h4 class="p-4">Dashboard</h4> -->
      <grouped-state />
      <grouped-system v-if="isAdmin" />
    </div>
  </section>
</template>

<script>
import GroupedState from "./GroupedState.vue";
import GroupedSystem from "./GroupedSystem.vue";

export default {
  components: {
    GroupedState,
    GroupedSystem
  },
  data() {
    return {
      estado: "",
      isAdmin: false
    };
  },
  created() {
    this.isAdmin = !!localStorage.adminnastro;
  },
  methods: {}
};
</script>
