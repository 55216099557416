<script>
import { Line } from "vue-chartjs";
import Service from "../../services/ApiServices";
export default {
  extends: Line,
  props: {
    estadoFilter: {
      type: Object
    },
    cidade_filter: {
      type: String
    }
  },
  data() {
    return {
      chartdata: {
        labels: [],
        datasets: [],
        borderWidth: 2,
        fill: false
      }
      // options: {
      //   legend: {
      //     display: false
      //   },
      //   scales: {
      //     xAxes: [
      //       {
      //         display: true,
      //         scaleLabel: {
      //           display: true,
      //           labelString: "Dias"
      //         }
      //       }
      //     ],
      //     yAxes: [
      //       {
      //         display: true,
      //         scaleLabel: {
      //           display: true,
      //           labelString: "Qtd. de Cadastros"
      //         }
      //       }
      //     ]
      //   },
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   tooltips: {
      //     mode: "index",
      //     intersect: false
      //   }
      // }
    };
  },
  async mounted() {
    this.populate();
  },
  methods: {
    async populate() {
      let filter = this.estadoFilter;
      if (filter == null) filter = {};
      if (Object.keys(filter).length > 0 || localStorage.adminnastro) {
        const request = new Service();
        const responseCadastros = await request.getAll(
          {
            estado: filter ? filter.name : "",
            cidade: this.cidade_filter || ""
          },
          `dashboards/GroupedDataCadastroEstabelecimentos`
        );

        if (responseCadastros) {
          let labels = [];
          let data = [
            {
              label: "Cadastros",
              data: [],
              borderColor: "#27ae60",
              backgroundColor: "#27ae60",
              fill: false
            }
          ];

          responseCadastros.forEach(e => {
            labels.push(e.dataCadastro.replace("-", "/"));
            data[0].data.push(e.count);
          });
          this.chartdata.labels = labels;
          this.chartdata.datasets = data;
          this.renderChart(this.chartdata, this.translateOptions());
        }
      }
    },
    translateOptions() {
      let options = {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("dashboard.dias")
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("dashboard.qtde-cadastros")
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          intersect: false
        }
      };

      return options;
    }
  },
  watch: {
    estadoFilter: function() {
      this.populate();
    },
    cidade_filter: function() {
      this.populate();
    }
  }
};
</script>

<style></style>
