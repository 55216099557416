<script>
import { Bar } from "vue-chartjs";
import ApiService from "../../services/ApiServices";

export default {
  extends: Bar,
  props: {
    estadoFilter: {
      type: Object
    },
    cidade_filter: {
      type: String
    }
  },
  data: () => ({
    chartdata: {
      labels: [],
      datasets: [
        {
          label: [],
          backgroundColor: [],
          data: []
        }
      ]
    }
    // options: {
    //   responsive: true,
    //   legend: {
    //     display: false
    //   },
    //   scales: {
    //     xAxes: [
    //       {
    //         display: true,
    //         scaleLabel: {
    //           display: true,
    //           labelString: "Categorias"
    //         }
    //       }
    //     ],
    //     yAxes: [
    //       {
    //         display: true,
    //         scaleLabel: {
    //           display: true,
    //           labelString: "Qtde. Estabelecimentos"
    //         }
    //       }
    //     ]
    //   },

    //   tooltips: {
    //     callbacks: {
    //       label: tooltipItem => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
    //       title: () => null
    //     }
    //   },
    //   maintainAspectRatio: false
    // }
  }),
  methods: {
    async populate() {
      let filter = this.estadoFilter;
      if (filter == null) filter = {};
      if (Object.keys(filter).length > 0 || localStorage.adminnastro) {
        const request = new ApiService();
        const resp = await request.getAll(
          {
            estado: filter ? filter.name : "",
            cidade: this.cidade_filter || ""
          },
          "dashboard/getQuantityEachCategoryType"
        );
        if (resp) {
          let labels = [];
          let data = [
            {
              data: [],
              label: [],
              borderColor: "#27ae60",
              backgroundColor: "#27ae60",
              fill: true
            }
          ];

          resp.forEach(element => {
            labels.push(element.establishmentTypes);
            data[0].label.push(element.establishmentTypes);
            data[0].data.push(element.quantity);
          });

          this.chartdata.labels = labels;
          this.chartdata.datasets = data;
        }

        this.renderChart(this.chartdata, this.translateOptions());
      }
    },
    translateOptions() {
      let options = {
        responsive: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("geral.labels.categorias")
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("dashboard.qtde-estabelecimentos")
              }
            }
          ]
        },

        tooltips: {
          callbacks: {
            label: tooltipItem =>
              `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
            title: () => null
          }
        },
        maintainAspectRatio: false
      };

      return options;
    }
  },

  mounted() {
    this.populate();
  },
  watch: {
    estadoFilter: function() {
      this.populate();
    },
    cidade_filter: function() {
      this.populate();
    }
  }
};
</script>
