<template>
  <section v-show="isAdmin">
    <!-- Informações do sistema -->
    <h4 class="text-center mt-3 pt-5">
      {{ $t("dashboard.informacoes-sistema") }}
    </h4>
    <hr class="divider my-4" />
    <section class="page__section page__section--cinza" ref="estatistica">
      <div class="d-flex justify-content-center text-center">
        <div class="m-2" v-for="card in cards" :key="card.icon">
          <div class="inform-partner-rounded">
            <div>
              <h4 class="font-weight-bold" style="color: #27ae60;">
                {{ card.number }}
              </h4>
              <h5>{{ $t("geral.labels." + card.title) }}</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="col-md-12 col-sm-12 col-12">
      <b-card class="m-4 background">
        <div class="text-center">
          <b>{{ $t("dashboard.estabelecimentos-por-estado") }}</b>
          <grouped-state />
        </div>
      </b-card>
    </div>

    <div class="col-md-12 col-sm-12 col-12">
      <b-card class="m-4 background">
        <div class="text-center">
          <b>{{ $t("dashboard.qtde-acessos-dia") }}</b>
          <grouped-acessos />
        </div>
      </b-card>
    </div>
    <div v-if="!!isAdmin" class="col-md-12 col-sm-12 col-12">
      <b-card class="m-4 background">
        <div class="text-center">
          <b>{{ $t("dashboard.qtde-buscas-por-estado") }}</b>
          <grouped-history-state />
        </div>
      </b-card>
    </div>
  </section>
</template>

<script>
import GroupedState from "../../components/Charts/GroupedState.vue";
import GroupedAcessos from "../../components/Charts/GroupedAcessos.vue";
import GroupedHistoryState from "../../components/Charts/GroupedHistoryState.vue";
import ApiService from "../../services/ApiServices";

export default {
  components: {
    GroupedState,
    GroupedAcessos,
    GroupedHistoryState
  },
  data() {
    return {
      isAdmin: false,
      estados: [],
      estadoFilter: {},
      cards: [
        {
          title: "acessos",
          number: 0
        },
        {
          title: "servicos",
          number: 0
        },
        {
          title: "cidades",
          number: 0
        },
        {
          title: "estados",
          number: 0
        }
      ]
    };
  },
  created() {
    this.isAdmin = localStorage.adminnastro;
    this.getEstados();
    this.getTotalAcessos();
    this.getTotalEstabelecimentos();
    this.getTotalCidades();
    this.getTotalEstados();
  },
  methods: {
    getEstados() {
      const request = new ApiService();
      request
        .getAll({ sort: "nome", size: 27 }, "estados")
        .then(res => (this.estados = res.content));
    },
    async getTotalAcessos() {
      const request = new ApiService();
      let requestResult = await request.totalAcessos(
        {},
        "usuarios/totalAcessos"
      );
      this.cards[0].number = requestResult ? requestResult.totalAcessos : 0;
      this.cards[0].description = `Já são ${this.cards[0].number} acessos e contando!`;
    },
    async getTotalEstabelecimentos() {
      const request = new ApiService();
      let requestResult = await request.totalAcessos({}, "empresas/count");
      this.cards[1].number = requestResult ? requestResult : 0;
      this.cards[1].description = `No total, temos ${this.cards[1].number} estabelecimentos cadastrados`;
    },
    async getTotalCidades() {
      const request = new ApiService();
      let requestResult = await request.totalAcessos({}, "custom/countCidades");
      this.cards[2].number = requestResult ? requestResult : 0;
      this.cards[2].description = `O #FiqueNoLar possui registros de ${this.cards[2].number} Cidades.`;
    },
    async getTotalEstados() {
      const request = new ApiService();
      let requestResult = await request.totalAcessos({}, "custom/countEstados");
      this.cards[3].number = requestResult ? requestResult : 0;
      this.cards[3].description = `${this.cards[3].number} estados já aderiram ao #FiqueNoLar`;
    }
  }
};
</script>

<style>
.background {
  background: #ecf0f1;
}
.selectFilter {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-collapse: unset;
  border-bottom-width: 1px;
  border-color: transparent transparent #555 transparent;
}
.cursor-context-menu {
  cursor: context-menu;
}
</style>
