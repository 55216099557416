<script>
import { Bar } from "vue-chartjs";
import Service from "../../services/ApiServices";
export default {
  extends: Bar,
  data() {
    return {
      colors: [
        "#6200EE",
        "#0B06A6",
        "#4E73DF",
        "#36B9CC",
        "#1CC88A",
        "#03DAC5",
        "#B2FF59",
        "#F6C23E",
        "#EF685C",
        "#EA80FC",
        "#7C4DFF",
        "#C0C6FB",
        "#C4C4C4",
        "#858796",
        "#344955",
        "#37966F",
        "#B9E4C9",
        "#FFC5B9",
        "#FF0266",
        "#9C2E85",
        "#E30425",
        "#FFDE03",
        "#FEF582",
        "#1B67B6",
        "#0336FF"
      ],
      chartdata: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: []
          }
        ]
      }
      // options: {
      //   legend: {
      //     display: false
      //   },
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   scales: {
      //     xAxes: [
      //       {
      //         display: true,
      //         scaleLabel: {
      //           display: true,
      //           labelString: "Estado"
      //         }
      //       }
      //     ],
      //     yAxes: [
      //       {
      //         display: true,
      //         scaleLabel: {
      //           display: true,
      //           labelString: "Qtd. de Buscas"
      //         }
      //       }
      //     ]
      //   }
      // }
    };
  },
  async mounted() {
    this.populate();
  },
  methods: {
    async populate() {
      const request = new Service();
      const responseHistorico = await request.getAll(
        {},
        `dashboards/GroupedHistoricoBuscaEstado`
      );

      if (responseHistorico) {
        let labels = [];

        let data = [
          {
            data: [],
            label: [],
            borderColor: "#27ae60",
            backgroundColor: "#27ae60",
            fill: false
          }
        ];
        responseHistorico.forEach(e => {
          data[0].data.push(e.count);

          labels.push(e.value);
        });

        this.chartdata.labels = labels;
        this.chartdata.datasets = data;
        this.renderChart(this.chartdata, this.translateOptions());
      }
    },
    translateOptions() {
      let options = {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("geral.labels.estado")
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("dashboard.qtde-buscas")
              }
            }
          ]
        }
      };

      return options;
    }
  }
};
</script>

<style></style>
