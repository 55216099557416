<script>
import { Pie } from "vue-chartjs";
import ApiService from "../../services/ApiServices";

export default {
  extends: Pie,
  props: {
    estadoFilter: {
      type: Object
    },
    cidade_filter: {
      type: String
    }
  },
  data: () => ({
    chartdata: {
      labels: [],
      datasets: [
        {
          backgroundColor: [],
          data: []
        }
      ]
    },
    colors: ["#27AE60", "#0B06A6", "#E46651"]
  }),
  methods: {
    async populate() {
      let filter = this.estadoFilter;
      if (filter == null) filter = {};
      if (Object.keys(filter).length > 0 || localStorage.adminnastro) {
        const request = new ApiService();
        const resp = await request.getAll(
          { estado: filter ? filter.name : "", cidade: this.cidade_filter },
          "dashboards/GroupedEstabelecimentosDelivery"
        );
        if (resp) {
          this.clearData();
          let labels = [];
          let arrAux = this.chartdata.datasets;

          resp.forEach((e, index) => {
            arrAux[0].backgroundColor.push(this.colors[index]);
            arrAux[0].data.push(e.count);
            labels.push(e.value + " - " + e.percentage + "%");
          });

          this.chartdata.labels = labels;
          this.chartdata.datasets = arrAux;
          this.renderChart(this.chartdata, this.translateOptions());
        }
      }
    },
    translateOptions() {
      let options = {
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      };

      return options;
    },
    clearData() {
      this.chartdata = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: []
          }
        ]
      };
    }
  },

  mounted() {
    this.populate();
  },
  watch: {
    estadoFilter: function() {
      this.populate();
    },
    cidade_filter: function() {
      this.populate();
    }
  }
};
</script>
