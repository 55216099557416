<script>
import { Bar } from "vue-chartjs";
import Service from "../../services/ApiServices";
export default {
  extends: Bar,
  props: ["estadoFilter", "cidade_filter"],
  data() {
    return {
      colors: [
        "#6200EE",
        "#0B06A6",
        "#4E73DF",
        "#36B9CC",
        "#1CC88A",
        "#03DAC5",
        "#B2FF59",
        "#F6C23E",
        "#EF685C",
        "#EA80FC",
        "#7C4DFF",
        "#C0C6FB",
        "#C4C4C4",
        "#858796",
        "#344955",
        "#37966F",
        "#B9E4C9",
        "#FFC5B9",
        "#FF0266",
        "#9C2E85",
        "#E30425",
        "#FFDE03",
        "#FEF582",
        "#1B67B6",
        "#0336FF"
      ],
      chartdata: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: []
          }
        ]
      }
      // options: {
      //   legend: {
      //     display: false
      //   },
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   tooltips: {
      //     callbacks: {
      //       label: tooltipItem => `${tooltipItem.label}: ${tooltipItem.value}`,
      //       title: () => null
      //     }
      //   },
      //   scales: {
      //     xAxes: [
      //       {
      //         display: true,
      //         scaleLabel: {
      //           display: true,
      //           labelString: "Categoria do estabelecimento"
      //         }
      //       }
      //     ],
      //     yAxes: [
      //       {
      //         display: true,
      //         scaleLabel: {
      //           display: true,
      //           labelString: "Qtd. de Buscas"
      //         }
      //       }
      //     ]
      //   }
      // }
    };
  },
  async mounted() {
    this.populate();
  },
  methods: {
    async populate() {
      const request = new Service();

      let filter = this.estadoFilter;
      if (filter == null) filter = {};
      if (Object.keys(filter).length > 0 || localStorage.adminnastro) {
        const responseHistorico = await request.getAll(
          {
            estado: filter ? filter.name : "",
            cidade: this.cidade_filter || ""
          },
          `dashboard/mostSearchedCategories`
        );

        if (responseHistorico) {
          let labels = [];

          let data = [
            {
              data: [],
              label: [],
              borderColor: "#27ae60",
              backgroundColor: "#27ae60",
              fill: false
            }
          ];
          responseHistorico.forEach(e => {
            data[0].data.push(e.count);

            labels.push(e.value);
          });

          this.chartdata.labels = labels;
          this.chartdata.datasets = data;
          this.renderChart(this.chartdata, this.translateOptions());
        }
      }
    },
    translateOptions() {
      let options = {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: tooltipItem => `${tooltipItem.label}: ${tooltipItem.value}`,
            title: () => null
          }
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("dashboard.categoria-estabelecimento")
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("dashboard.qtde-buscas")
              }
            }
          ]
        }
      };

      return options;
    }
  },
  watch: {
    estadoFilter() {
      this.populate();
    },
    cidade_filter: function() {
      this.populate();
    }
  }
};
</script>

<style></style>
