<script>
import { Line } from "vue-chartjs";
import Service from "../../services/ApiServices";
export default {
  extends: Line,
  data() {
    return {
      chartdata: {
        labels: [],
        datasets: [],
        borderWidth: 2,
        fill: false
      }
      // options: {
      //   legend: {
      //     display: false
      //   },
      //   scales: {
      //     xAxes: [
      //       {
      //         display: true,
      //         scaleLabel: {
      //           display: true,
      //           labelString: "Dias"
      //         }
      //       }
      //     ],
      //     yAxes: [
      //       {
      //         display: true,
      //         scaleLabel: {
      //           display: true,
      //           labelString: "Qtd. de Acessos"
      //         }
      //       }
      //     ]
      //   },
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   tooltips: {
      //     mode: "index",
      //     intersect: false
      //   }
      // }
    };
  },
  async mounted() {
    this.populate();
  },
  methods: {
    async populate() {
      const request = new Service();
      const responseAcessos = await request.getAll(
        {},
        `dashboards/GroupedAcessos`
      );

      if (responseAcessos) {
        let labels = [];
        let data = [
          {
            label: "Acessos",
            data: [],
            borderColor: "#27ae60",
            backgroundColor: "#27ae60",
            fill: false
          }
        ];

        responseAcessos.forEach(e => {
          labels.push(e.dataAcesso.replace("-", "/"));

          data[0].data.push(e.count);
        });
        this.chartdata.labels = labels;
        this.chartdata.datasets = data;
        this.renderChart(this.chartdata, this.translateOptions());
      }
    },
    translateOptions() {
      let options = {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("dashboard.dias")
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("dashboard.qtde-acessos")
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          intersect: false
        }
      };

      return options;
    }
  }
};
</script>

<style></style>
