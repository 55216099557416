<script>
import { HorizontalBar } from "vue-chartjs";
import ApiService from "../../services/ApiServices";

export default {
  extends: HorizontalBar,
  props: { estadoFilter: { type: Object } },
  data() {
    return {
      showSpinner: false,
      chartdata: {
        labels: [],
        datasets: [
          {
            label: [],
            data: [],
            borderColor: "#EA3546",
            backgroundColor: "#EA3546",
            fill: false
          }
        ]
      }
      // options: {
      //   legend: {
      //     display: false
      //   },
      //   scales: {
      //     xAxes: [
      //       {
      //         display: true,
      //         scaleLabel: {
      //           display: true,
      //           labelString: "Quantidade de estabelecimentos"
      //         }
      //       }
      //     ]
      //   },

      //   responsive: true,
      //   maintainAspectRatio: false,
      //   tooltips: {
      //     callbacks: {
      //       label: tooltipItem =>
      //         `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
      //       title: () => null
      //     }
      //   }
      // }
    };
  },
  methods: {
    async populate() {
      let filter = this.estadoFilter;
      if (filter == null) filter = {};
      if (Object.keys(filter).length > 0 || localStorage.adminnastro) {
        const request = new ApiService();
        const resp = await request.getAll(
          { estado: filter ? filter.name : "" },
          "dashboards/GroupedCidadesTop10"
        );
        if (resp) {
          let labels = [];
          let data = [
            {
              data: [],
              label: [],
              borderColor: "#27ae60",
              backgroundColor: "#27ae60",
              fill: false
            }
          ];

          resp.forEach(e => {
            data[0].data.push(e.count);
            data[0].label.push(e.cidadeUf);
            labels.push(e.cidadeUf);
          });

          this.chartdata.labels = labels;
          this.chartdata.datasets = data;
          this.showSpinner = false;
        }
        this.renderChart(this.chartdata, this.translateOptions());
      }
    },
    translateOptions() {
      let options = {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("dashboard.qtde-estabelecimentos")
              }
            }
          ]
        },

        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: tooltipItem =>
              `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
            title: () => null
          }
        }
      };

      return options;
    }
  },
  async mounted() {
    this.populate();
  },
  watch: {
    estadoFilter: function() {
      this.populate();
    }
  }
};
</script>
