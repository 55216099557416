<template>
  <section>
    <!-- Informações do estado -->

    <div v-if="isMunicipio">
      <h4 class="text-center mt-0">{{ informacoes }}</h4>
      <hr class="divider my-4" />
      <section class="page__section page__section--cinza" ref="estatistica">
        <div class="d-flex justify-content-center text-center">
          <div class="m-2">
            <div class="inform-partner-rounded">
              <div>
                <h4 class="font-weight-bold" style="color: #27ae60;">
                  {{ cards[0].number }}
                </h4>
                <h5>{{ $t("geral.labels." + cards[0].title) }}</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <h4 class="text-center mt-0">{{ informacoes }}</h4>
      <hr class="divider my-4" />
      <section
        v-if="filter_access"
        class="page__section page__section--cinza"
        ref="estatistica"
      >
        <div class="d-flex justify-content-center text-center">
          <div class="m-2" v-for="card in cards" :key="card.icon">
            <div class="inform-partner-rounded">
              <div>
                <h4 class="font-weight-bold" style="color: #27ae60;">
                  {{ card.number }}
                </h4>
                <h5>{{ $t("geral.labels." + card.title) }}</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Filtro de estado -->
    <div
      v-if="!estadoSelectDisabled"
      class="row d-flex align-items-center justify-content-center"
    >
      <span class>{{ $t("dashboard.filtrar-por-estado") }}:</span>
      <div class="col-md-6 mt-1">
        <v-select
          id="input-1"
          v-model="estadoFilter"
          :options="estados"
          :placeholder="'Selecione o Estado'"
          label="name"
          :disabled="estadoSelectDisabled"
          @input="getTotalEstabAndCidaByEstado()"
        ></v-select>
      </div>
    </div>

    <!-- filtro de cidade -->
    <div
      v-if="filter_access && (!estadoSelectDisabled || !cidadeSelectDisabled)"
      class="row d-flex align-items-center justify-content-center"
    >
      <!--<span class>{{ ("Filtrar por Cidade") }}:</span>-->
      <span class> {{ $t("dashboard.filtrar-por-cidade") }}: </span>
      <div class="col-md-6 mt-1">
        <v-select
          id="input-2"
          v-model="cidade_filter"
          :options="cidades"
          :placeholder="
            load_list_cities && estadoFilter
              ? 'Carregando cidades...'
              : 'Selecione a Cidade'
          "
          label="name"
          :disabled="!estadoFilter || load_list_cities"
          @input="getTotalEstabelecimentosBycity()"
        ></v-select>
      </div>
    </div>

    <!-- Gráfico top 10 -->
    <div v-if="!cidade_filter" class="col-md-12 col-sm-12 col-12">
      <b-card class="m-4 background">
        <div class="text-center">
          <b>
            {{ $t("dashboard.estabelecimentos-por-cidade-t10") }}
            <span
              v-if="estadoFilter"
              class="ml-1 badge badge-pill badge-success cursor-context-menu"
              :title="
                $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
              "
              >{{ estadoFilter.name }}</span
            >
          </b>
          <grouped-city :estadoFilter="estadoFilter" />
        </div>
      </b-card>
    </div>

    <!--
    <div class="col-md-12 col-sm-12 col-12">
      <b-card class="m-4 background">
        <div class="text-center">
          <b>
            {{ $t("dashboard.qtde-cadastros-dia") }}
            <span
              v-if="estadoFilter"
              class="ml-1 badge badge-pill badge-success cursor-context-menu"
              :title="
                $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
              "
            >{{ estadoFilter.name }}</span>
          </b>
          <grouped-cadastro :estadoFilter="estadoFilter" />
        </div>
      </b-card>
    </div>
    -->

    <!-- categoria de cadastros por dia -->
    <div class="col-md-12 col-sm-12 col-12">
      <b-card class="m-4 background">
        <div class="text-center">
          <b>
            {{ $t("dashboard.qtde-cadastros-dia") }}
            <span
              v-if="estadoFilter"
              class="ml-1 badge badge-pill badge-success cursor-context-menu"
              :title="
                $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
              "
              >{{ estadoFilter.name }}</span
            >
            <span
              v-if="cidade_filter"
              class="ml-1 badge badge-pill badge-success cursor-context-menu"
              :title="
                $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
              "
              >{{ cidade_filter }}</span
            >
          </b>
          <grouped-cadastro
            :estadoFilter="estadoFilter"
            :cidade_filter="cidade_filter"
          />
        </div>
      </b-card>
    </div>

    <!--
    <div class="col-md-12 col-sm-12 col-12">
      <b-card class="m-4 background">
        <div class="text-center">
          <b>
            {{ $t("dashboard.categorias-estabelecimentos") }}
            <span
              v-if="estadoFilter"
              class="ml-1 badge badge-pill badge-success cursor-context-menu"
              :title="
                $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
              "
            >{{ estadoFilter.name }}</span>
          </b>
          <grouped-estabelecimentos :estadoFilter="estadoFilter" />
        </div>
      </b-card>
    </div>
    -->

    <!-- categoria de estabelecimento cidade-->
    <div class="col-md-12 col-sm-12 col-12">
      <b-card class="m-4 background">
        <div class="text-center">
          <b>
            {{ $t("dashboard.categorias-estabelecimentos") }}
            <span
              v-if="estadoFilter"
              class="ml-1 badge badge-pill badge-success cursor-context-menu"
              :title="
                $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
              "
              >{{ estadoFilter.name }}</span
            >
            <span
              v-if="estadoFilter"
              class="ml-1 badge badge-pill badge-success cursor-context-menu"
              :title="$t('dashboard.filtrando-por-cidade') + `${cidade_filter}`"
              >{{ cidade_filter }}</span
            >
          </b>
          <grouped-estabelecimentos
            :estadoFilter="estadoFilter"
            :cidade_filter="cidade_filter"
          />
        </div>
      </b-card>
    </div>

    <!-- categoria de mais buscados 
    <div class="col-md-12 col-sm-12 col-12">
      <b-card class="m-4 background">
        <div class="text-center">
          <b>
            {{ $t("dashboard.categorias-mais-buscadas") }}
            <span
              v-if="estadoFilter"
              class="ml-1 badge badge-pill badge-success cursor-context-menu"
              :title="
                $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
              "
            >{{ estadoFilter.name }}</span>
          </b>
          <grouped-historico-busca-tipo-estabelecimento :estadoFilter="estadoFilter" />
        </div>
      </b-card>
    </div>
    -->

    <div class="col-md-12 col-sm-12 col-12">
      <b-card class="m-4 background">
        <div class="text-center">
          <b>
            {{ $t("dashboard.categorias-mais-buscadas") }}
            <span
              v-if="estadoFilter"
              class="ml-1 badge badge-pill badge-success cursor-context-menu"
              :title="
                $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
              "
              >{{ estadoFilter.name }}</span
            >
            <span
              v-if="cidade_filter"
              class="ml-1 badge badge-pill badge-success cursor-context-menu"
              :title="
                $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
              "
              >{{ cidade_filter }}</span
            >
          </b>
          <grouped-historico-busca-tipo-estabelecimento
            :estadoFilter="estadoFilter"
            :cidade_filter="cidade_filter"
          />
        </div>
      </b-card>
    </div>

    <!-- 
    Destinado ao grafico de redes sociais
    -->

    <div class="col-md-12 col-sm-12 col-12">
      <b-card class="m-4 background">
        <div class="text-center">
          <b>
            {{ $t("dashboard.qtde-redes-socias") }}
            <span
              v-if="estadoFilter"
              class="ml-1 badge badge-pill badge-success cursor-context-menu"
              :title="
                $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
              "
              >{{ estadoFilter.name }}</span
            >
            <span
              v-if="cidade_filter"
              class="ml-1 badge badge-pill badge-success cursor-context-menu"
              :title="
                $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
              "
              >{{ cidade_filter }}</span
            >
          </b>
          <grouped-redes-sociais
            :estadoFilter="estadoFilter"
            :cidade_filter="cidade_filter"
          />
        </div>
      </b-card>
    </div>

    <!-- Novos Charts ----------------------------------------- -->

    <!-- Charts CNPJ -->
    <div class="row">
      <div class="col-md-6 col-sm-12 col-12">
        <b-card class="m-4 background">
          <div class="text-center">
            <b>
              {{ $t("dashboard.estab-cnpj") }}
              <span
                v-if="estadoFilter"
                class="ml-1 badge badge-pill badge-success cursor-context-menu"
                :title="
                  $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
                "
                >{{ estadoFilter.name }}</span
              >

              <span
                v-if="cidade_filter"
                class="ml-1 badge badge-pill badge-success cursor-context-menu"
                :title="
                  $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
                "
                >{{ cidade_filter }}</span
              >
            </b>
            <grouped-formais-informais
              :estadoFilter="estadoFilter"
              :cidade_filter="cidade_filter"
            />
          </div>
        </b-card>
      </div>

      <!-- Charts Delivery Retirada -->
      <div class="col-md-6 col-sm-12 col-12">
        <b-card class="m-4 background">
          <div class="text-center">
            <b>
              {{ $t("dashboard.estab-delivery-retirada") }}
              <span
                v-if="estadoFilter"
                class="ml-1 badge badge-pill badge-success cursor-context-menu"
                :title="
                  $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
                "
                >{{ estadoFilter.name }}</span
              >
              <span
                v-if="cidade_filter"
                class="ml-1 badge badge-pill badge-success cursor-context-menu"
                :title="
                  $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
                "
                >{{ cidade_filter }}</span
              >
            </b>
            <grouped-delivery-retirada
              :estadoFilter="estadoFilter"
              :cidade_filter="cidade_filter"
            />
          </div>
        </b-card>
      </div>

      <!-- Charts Delivery -->
      <div class="col-md-6 col-sm-12 col-12">
        <b-card class="m-4 background">
          <div class="text-center">
            <b>
              {{ $t("dashboard.estab-delivery") }}
              <span
                v-if="estadoFilter"
                class="ml-1 badge badge-pill badge-success cursor-context-menu"
                :title="
                  $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
                "
                >{{ estadoFilter.name }}</span
              >
              <span
                v-if="cidade_filter"
                class="ml-1 badge badge-pill badge-success cursor-context-menu"
                :title="
                  $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
                "
                >{{ cidade_filter }}</span
              >
            </b>
            <grouped-delivery
              :estadoFilter="estadoFilter"
              :cidade_filter="cidade_filter"
            />
          </div>
        </b-card>
      </div>

      <!-- Charts Estabelecimento e Retirada -->
      <div class="col-md-6 col-sm-12 col-12">
        <b-card class="m-4 background">
          <div class="text-center">
            <b>
              {{ $t("dashboard.estab-retirada") }}
              <span
                v-if="estadoFilter"
                class="ml-1 badge badge-pill badge-success cursor-context-menu"
                :title="
                  $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
                "
                >{{ estadoFilter.name }}</span
              >
              <span
                v-if="cidade_filter"
                class="ml-1 badge badge-pill badge-success cursor-context-menu"
                :title="
                  $t('dashboard.filtrando-por-estado') + `${estadoFilter.name}`
                "
                >{{ cidade_filter }}</span
              >
            </b>
            <grouped-retirada
              :estadoFilter="estadoFilter"
              :cidade_filter="cidade_filter"
            />
          </div>
        </b-card>
      </div>
    </div>

    <!-- ------------------------------------------------------------------ -->
  </section>
</template>

<script>
import GroupedCity from "../../components/Charts/GroupedCity.vue";
import GroupedCadastro from "../../components/Charts/GroupedCadastro.vue";
import GroupedEstabelecimentos from "../../components/Charts/GroupedEstabelecimentos.vue";
import GroupedHistoricoBuscaTipoEstabelecimento from "../../components/Charts/GroupedHistoricoBuscaTipoEstabelecimento.vue";
import GroupedDelivery from "../../components/Charts/GroupedDelivery.vue";
import GroupedRetirada from "../../components/Charts/GroupedRetirada.vue";
import GroupedDeliveryRetirada from "../../components/Charts/GroupedDeliveryRetirada.vue";
import GroupedFormaisInformais from "../../components/Charts/GroupedFormaisInformais.vue";
import GroupedRedesSociais from "../../components/Charts/GroupedRedesSociais.vue";
import ApiService from "../../services/ApiServices";

export default {
  components: {
    GroupedCity,
    GroupedCadastro,
    GroupedEstabelecimentos,
    GroupedHistoricoBuscaTipoEstabelecimento,
    GroupedDelivery,
    GroupedRetirada,
    GroupedDeliveryRetirada,
    GroupedFormaisInformais,
    GroupedRedesSociais
  },
  data() {
    return {
      filter_access: false,
      load_list_cities: true,
      show: false,
      estados: [],
      cidades: [],
      establishment_categories_filter: [],
      listaCidades: {},
      cidade_filter: "",
      estadoFilter: "",
      estadoSelectDisabled: true,
      cidadeSelectDisabled: true,
      cards: [
        {
          title: "servicos",
          number: 0
        },
        {
          title: "cidades",
          number: 0
        }
      ]
    };
  },
  computed: {
    informacoes() {
      return `Informações do ${
        localStorage.partnerLevel === "state" || localStorage.adminnastro
          ? "Estado"
          : "Município"
      }`;
    },
    isMunicipio() {
      return !(
        localStorage.partnerLevel === "state" || localStorage.adminnastro
      );
    }
  },
  async created() {
    if (localStorage.adminnastro) {
      this.getEstados();
      this.estadoSelectDisabled = false;
      this.cidadeSelectDisabled = false;
      this.filter_access = true;
    } else if (localStorage.partneraccess) {
      if (localStorage.partnerLevel === "state") {
        this.filter_access = true;
      }
      this.cidadeSelectDisabled = false;
      await this.getLocalById(localStorage.partneraccess);
    }
    this.getTotalEstabelecimentos(this.estadoFilter.name, this.cidade_filter);
    this.getTotalCidades(this.estadoFilter.name);
  },
  methods: {
    getTotalEstabAndCidaByEstado() {
      this.cidade_filter = "";
      this.getTotalEstabelecimentos(this.estadoFilter?.name);
      this.getTotalCidades(this.estadoFilter?.name);
      this.getListaDeCidades();
    },
    getTotalEstabelecimentosBycity() {
      this.getTotalEstabelecimentos(
        this.estadoFilter?.name,
        this.cidade_filter
      );
    },
    filterbyEstablishmentCategories(todos_estabelecimentos) {
      const array_de_objs = [];
      const set = new Set();

      todos_estabelecimentos.forEach(estabelecimento => {
        estabelecimento.tiposEstabelecimento.forEach(tipo => {
          if (set.has(tipo.nome)) {
            array_de_objs.filter(e => e.categoria === tipo.nome)[0]
              .quantidade++;
          } else {
            set.add(tipo.nome);
            let obj = {
              categoria: tipo.nome,
              quantidade: 1
            };
            array_de_objs.push(obj);
          }
        });
      });

      return array_de_objs;
    },

    async doFiltering() {
      const todos_estabelecimentos = await this.getAllEstabelecimentos();

      this.establishment_categories_filter = this.filterbyEstablishmentCategories(
        todos_estabelecimentos
      );
    },
    async getAllEstabelecimentos() {
      const request = new ApiService();
      let last_page = false;
      let page = 0;
      let todos_estabelecimentos = [];

      while (last_page === false) {
        let filtro = this.queryFilter(this.estadoFilter.name, page);
        let response = await request.findByParams(
          filtro,
          "empresas/findByParams"
        );
        todos_estabelecimentos = todos_estabelecimentos.concat(
          Array.from(response.content)
        );
        last_page = response.last;
        page++;
      }

      return todos_estabelecimentos;
    },
    queryFilter(page, estado = "") {
      return {
        tipoEstabelecimento: [],
        diasFuncionamento: "",
        isDelivery: "",
        isPermiteRetirada: "",
        cidade: "Aracati",
        estado: estado,
        search: "",
        bairro: "",
        page: page,
        size: 2000,
        sort: "cidadeObj.nome"
      };
    },

    getEstados() {
      const request = new ApiService();
      request
        .getAll({ sort: "nome", size: 27 }, "estados")
        .then(res => (this.estados = res.content));
    },
    async getListaDeCidades() {
      const request = new ApiService();

      this.load_list_cities = true;
      const list = await request.getAll(
        { sort: "nome", nomeEstado: this.estadoFilter.name },
        "cidades/findByEstado"
      );
      this.cidades = list.content.map(objCidade => objCidade.nome);
      this.load_list_cities = false;
    },
    getTotaldeCidades() {
      this.getTotalEstabelecimentos(this.listaCidades.name);
      this.getTotalCidades(this.listaCidades.name);
      this.getListaDeCidades();
    },
    async getLocalById(id = "") {
      const request = new ApiService();
      let resp = await request.getById(id, "estados/" + id);
      if (resp) {
        this.estadoFilter = resp;
        this.getListaDeCidades();
        return;
      }
      resp = await request.getById(id, "cidades/" + id);
      if (resp) {
        this.estadoFilter = resp.state;
        this.cidade_filter = resp.nome;
      }
    },
    async getTotalEstabelecimentos(estado = "", cidade = "") {
      const request = new ApiService();
      let requestResult = await request.findByParams(
        { estado, cidade },
        "empresas/count"
      );
      this.cards[0].number = requestResult ? requestResult : 0;
    },
    async getTotalCidades(estado = "") {
      const request = new ApiService();
      let requestResult = await request.findByParams(
        { estado },
        "custom/countCidades"
      );
      this.cards[1].number = requestResult ? requestResult : 0;
    }
  }
};
</script>

<style>
.background {
  background: #ecf0f1;
}
.selectFilter {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-collapse: unset;
  border-bottom-width: 1px;
  border-color: transparent transparent #555 transparent;
}
.cursor-context-menu {
  cursor: context-menu;
}
</style>
