<script>
import { HorizontalBar } from "vue-chartjs";
import ApiService from "../../services/ApiServices";

export default {
  extends: HorizontalBar,
  data: () => ({
    chartdata: {
      labels: [],
      datasets: [
        {
          label: [],
          data: [],
          borderColor: "#EA3546",
          backgroundColor: "#EA3546",
          fill: false
        }
      ]
    }
    // options: {
    //   legend: {
    //     display: false
    //   },
    //   scales: {
    //     xAxes: [
    //       {
    //         display: true,
    //         scaleLabel: {
    //           display: true,
    //           labelString: "Quantidade de estabelecimentos"
    //         }
    //       }
    //     ]
    //   },

    //   responsive: true,
    //   maintainAspectRatio: false,
    //   tooltips: {
    //     callbacks: {
    //       label: tooltipItem => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
    //       title: () => null
    //     }
    //   }
    // }
  }),
  methods: {
    update() {
      this.$data._chart.update();
    },
    translateOptions() {
      let options = {
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("dashboard.qtde-estabelecimentos")
              }
            }
          ]
        },

        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: tooltipItem =>
              `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
            title: () => null
          }
        }
      };
      return options;
    }
  },
  async mounted() {
    const request = new ApiService();
    const resp = await request.getAll({}, "dashboards/GroupedEstados");
    if (resp) {
      let labels = [];
      let data = [
        {
          data: [],
          label: [],
          borderColor: "#27ae60",
          backgroundColor: "#27ae60",
          fill: false
        }
      ];
      resp.forEach(e => {
        data[0].data.push(e.count);
        data[0].label.push(e.nome);
        labels.push(e.nome);
      });

      this.chartdata.labels = labels;
      this.chartdata.datasets = data;
    }

    this.renderChart(this.chartdata, this.translateOptions());
  }
};
</script>
